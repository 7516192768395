import React from 'react';
import { FaStar } from 'react-icons/fa';

const Rating = (props) => {

  if (props.compact) {
    return (
      <strong title="Hodnocení" {...props}>
        <FaStar className="icon text-primary" />&nbsp;{props.value}
      </strong>
    );
  }

  let stars = [];

  for (let i = 1; i <= 5; i++) {
    stars.push(i <= props.value ? "primary" : "darker");
  }

  return (
    <span {...props}>
      {stars.map((color, id) => <FaStar key={id} className={"icon text-" + color} />)}
    </span>
  );
};

export default Rating;
