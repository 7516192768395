export default (path) => {

  const urlTokens = path.split('/');
  const name = urlTokens.pop();
  urlTokens.push('thumb');
  urlTokens.push(name);

  return urlTokens.join('/');

}
