import googleMapsApi from 'utils/googleMapsApi';

export default (placeId, callback) => {
  
  googleMapsApi.then(google => {
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({
      placeId: placeId,
      fields: ['photos', 'rating', 'user_ratings_total', 'reviews']
    }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        callback({
          photos: !place.photos ? [] : place.photos.map(p => ({
            'url': p.getUrl({maxWidth: 1600}),
            'thumbUrl': p.getUrl({maxWidth: 200})
          })),
          reviews: !place.reviews ? [] : place.reviews.map(r => ({
            author: r.author_name,
            text: r.text,
          })),
          rating: place.rating ? place.rating : null,
          ratingTotal: place.user_ratings_total ? place.user_ratings_total : null,
        });
      }
    });
  });

}
