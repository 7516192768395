import { googleMapsApiKey as apiKey } from 'config/config';

const googleMapsApi = new Promise((resolve, reject) => {

  if (window.google) {
    resolve(window.google);
  }

  const script = document.createElement("script");

  script.src = "https://maps.googleapis.com/maps/api/js"
    + "?key=" + apiKey
    + "&libraries=places"
    + "&callback=handleGoogleMapsLoaded"
    + "&v=3.40";

  script.type = 'text/javascript';
  script.id = 'googleMaps';
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  window.handleGoogleMapsLoaded = () => {
    resolve(window.google)
  };

  setTimeout(() => {
    if (!window.google) {
      console.error('Loading google maps took too long')
      reject();
    }
  }, 30000);
});

export default googleMapsApi;
