import React, { useReducer, useEffect, useCallback } from 'react';
import GroundListContext from './GroundListContext';
import useApi from 'utils/useApi';
import distanceCalculator from 'utils/distanceCalculator';

const GroundListContextProvider = (props) => {

  const api = useApi();

  const [grounds, groundsDispatch] = useReducer((grounds, action) => {
    switch (action.type) {
      case 'set':
        if (!action.grounds) {
          throw new Error('You have to specify grounds.');
        }

        return action.grounds;

      default:
        throw new Error("Unexpected action " + action.type);
    }
  }, []);

  const fetchGrounds = useCallback(async () => {
    api('GET', 'grounds').then(grounds => groundsDispatch({ type: 'set', grounds }));
  }, [api]);

  useEffect(() => {
    if (grounds.length === 0) {
      fetchGrounds();
    }
  }, [grounds.length, fetchGrounds]);

  const sortByDistance = async (location) => {
    let promise = new Promise((resolve) => {
      resolve(grounds.map(ground => {
          const distance = distanceCalculator(
            location.lat,
            location.lng,
            ground.lat,
            ground.lng
          );
    
          return { ...ground, distance }
        }).sort((a, b) => a.distance - b.distance)
      );
    })

    const sortedGrounds = await promise;

    groundsDispatch({ type: 'set', grounds: sortedGrounds});
  };

  return (
    <GroundListContext.Provider value={{ grounds, sortByDistance, groundsDispatch }}>
      {props.children}
    </GroundListContext.Provider>
  );
}

export default GroundListContextProvider;