import React, { useContext, useRef } from 'react';
import GroundList from './GroundList';
import GroundDetail from './GroundDetail';
import { Button } from 'react-bootstrap';
import AppContext from 'context/App/AppContext';
import useCompactLayout from "utils/useCompactLayout";
import { Link } from "react-router-dom";

const Ground = () => {

  const stepRef = useRef(null);
  const { ground, location } = useContext(AppContext);
  const compact = useCompactLayout();

  return (
    <div id="step2" ref={stepRef} className={"step" + (!location ? " disabled" : "")}>

      <h4>
        <strong className="stepNumber">02 </strong>
        {ground ? (
            <>
              Hřiště
              <Link to='/'><Button
                variant="secondary"
                size="sm"
                className="ml-2 align-text-bottom"
              >
                Změnit
              </Button></Link>
            </>
          ) : "Vyber hřiště" 
        }
        {compact && location &&
          <Link to="/map">
            <Button
              size="sm"
              className="ml-2 align-text-bottom float-right"
            >
              Mapa
            </Button>
          </Link>
        }
      </h4>

      <div className="stepContent">
        {ground && <GroundDetail ground={ground} />}
        <div className={ground ? 'd-none' : ''}>
            <GroundList location={location} />
        </div>
      </div>

    </div>
  );

}

export default Ground;
