import React, { useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom"

const Navigation = ({ hideNav }) => {

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      hideNav();
    });

    return () => unlisten();
  }, [history, hideNav]);

  return (
    <nav className="main-nav">
      <NavLink to="/" exact={true}>Hlavní strana</NavLink>
      <NavLink to="/trainer">Trenérská sekce</NavLink>
      <NavLink to="/about">O projektu</NavLink>
      <NavLink to="/contact">Kontakt</NavLink>
    </nav>
  );
};

export default Navigation;
