import { useCallback } from 'react';
import { apiUrl } from 'config/config';
import { useCookies } from 'react-cookie';

const useApi = () => {

  const cookies = useCookies(['accessToken'])[0];

  const fetchApi = useCallback((method, route, body) => {
    return new Promise(async (resolve, reject) => {

      const formData = body instanceof FormData;

      let headers = {
        'Accept': 'application/json',
      };

      if (!formData) {
        headers = {...headers, 'Content-Type': 'application/json'};
      }

      if (cookies.accessToken) {
        headers = {...headers, 'Authorization': 'Bearer ' + cookies.accessToken};
      }

      const response = await fetch(apiUrl + route, {
        method: method,
        headers: headers,
        body: formData ? body : JSON.stringify(body),
      }).catch(error => {
        console.log(error);
      });

      if (!response) {
        reject('Selhalo spojení se serverem, zkus to prosím později');
        return;
      }

      const data = await response.json().catch(error => {
        console.log(error);
      });

      if (!response.ok) {
        if (data && data.error) {
          reject(data.error);
        } else {
          if (response.status === 404) {
            window.location.href = '/404';
            return;
          }
          reject("Neočekávaná chyba na serveru, už na tom pracujeme.");
          console.log(response);
          return;
        }
      }

      resolve(data);
    });
  }, [cookies.accessToken]);

  return fetchApi;
};

export default useApi;
