import React, { useReducer, useEffect, useState } from 'react';
import useApi from 'utils/useApi';
import { useCookies } from 'react-cookie';
import UserContext from './UserContext';

const UserContextProvider = (props) => {

  const api = useApi();

  const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
  const [loading, setLoading] = useState(true);

  const [user, userDispatch] = useReducer((user, action) => {
    switch (action.type) {
      case 'set':
        setLoading(false);
        return action.user;

      case 'logout':
        return null;

      default:
        throw new Error("Unexpected action " + action.type);
    }
  }, null);

  const setAccessToken = (token) => {
    if (token) {
      setCookie('accessToken', token, { path: '/', maxAge: 2592000});
      setLoading(true);
    }
  };

  // log user from cookie
  useEffect(() => {
    if (loading) {
      if (cookies.accessToken) {
        api('GET', 'user')
          .then(user => {
            userDispatch({ type: "set", user })
            setLoading(false);
          }).catch(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [loading, api, cookies.accessToken]);

  // clear cookie on logout
  useEffect(() => {
    if (!loading && !user) {
      removeCookie('accessToken', { path: '/'});
    }
  }, [loading, removeCookie, user]);

  return (
    <UserContext.Provider value={{
      user,
      accessToken: cookies.accessToken,
      userLoading: loading,
      setAccessToken,
      refreshUser: () => setLoading(true),
      userDispatch,
    }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;