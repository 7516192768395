import React, { useReducer } from 'react';
import NotificationContext from './NotificationContext';

const NotificationContextProvider = (props) => {

  const [notifications, notificationDispatch] = useReducer((notifications, action) => {
    switch (action.type) {
      case 'set-multiple':
        return action.notifications;
      case 'clear':
        return [];
      case 'remove':
        const index = notifications.findIndex(n => n.id === action.id);
        let n = [...notifications];

        if (index !== -1) {
          n.splice(index, 1)
        }

        return n;
      default: 
        return [...notifications, {
          id: Date.now().toString(),
          type: action.type,
          message: action.message,
          permanent: action.permanent ? true : false,
        }];
    }
  }, []);

  return (
    <NotificationContext.Provider value={{
      notifications,
      notificationDispatch,
    }}>
      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationContextProvider;