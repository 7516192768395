import React, { useState } from 'react';
import Spinner from 'components/Spinner';
import Avatar from 'components/Avatar';
import Rating from 'components/Rating';
import { Badge, Button } from 'react-bootstrap';
import { FaChevronRight, FaTimes } from 'react-icons/fa';

const TrainerDetail = ({ trainer }) => {

  const [state, setState] = useState({
    view: null,
    ratingPage: 0,
  });

  const handleNextRating = () => {
    setState(prev => ({
      ...prev, 
      ratingPage: prev.ratingPage + 1
    }));
  };

  const handleNav = (view) => {
    setState(prev => ({
      ...prev,
      view: prev.view === view ? null : view
    }));
  };

  if (trainer.loaded === false) {
    return <Spinner />;
  }

  return (
    <>
      <div className="stepItem active">
        <div className={!trainer.active ? 'not-active' : ''}>
          <Avatar path={trainer.avatar} size="sm" clickable={true}/>
        </div>
        <div className="ml-3">
          <strong>{trainer.name}</strong><br />
          {trainer.active ? (
            <span className="rating">
              {trainer.rating ? (
                <span title="Hodnocení trenéra od klientů">
                  <Rating value={trainer.rating} />
                  <small className="ml-2">
                    <strong>{trainer.rating} <Badge variant="light">{trainer.ratingsCount}x</Badge></strong>
                  </small>
                </span>
              ) : (
                <small title="Své první hodnocení trenér ještě neobdržel.">
                  Zatím nehodnocen
                </small>
              )}
            </span>
          ) : (
            <span>
              <small>Dočasně nepřijímá další klienty</small>
            </span>
          )}
        </div>
      </div>
      <nav className="stepNav">
        {trainer.about.length > 0 && (
          <Button variant="link" className={state.view === 'about' ? ' active' : ''} onClick={() => handleNav('about')}>
            O mně
          </Button>
        )}
        {trainer.priceList.length > 0 && (
          <Button variant="link" className={state.view === 'priceList' ? ' active' : ''} onClick={() => handleNav('priceList')}>
            Ceník
          </Button>
        )}
        <Button variant="link" className={state.view === 'ratings' ? ' active' : ''} onClick={() => handleNav('ratings')}>
          Recenze
        </Button>
      </nav>

      {state.view === 'about' && 
        <div className="stepNavContent">
          {trainer.about}
        </div>
      }

      {state.view === 'priceList' && 
        <table className="stepNavContent priceList">
          <tbody>
            {trainer.priceList.map((i, index) => (
              <tr key={index} className="priceListItem">
                <td>{i.name}</td>
                <td className="ml-auto text-right pl-3">{i.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }

      {state.view === 'ratings' && (<>
        {trainer.ratings.length === 0 ? (
          <div className="stepNavContent">
            Trenér je tu nový a zatím ještě neobdržel žádné hodnocení.
          </div>
        ) : (
          <div className="groundReviews">
            <div className="review">
              <blockquote>
                „{trainer.ratings[state.ratingPage].text}“ 
                <span className="text-darker"> - {trainer.ratings[state.ratingPage].customerName}</span>
              </blockquote>
            </div>
            {trainer.ratings.length - 1 > state.ratingPage ? (
              <Button variant="link" className="groundNext ml-auto" title="Další" onClick={handleNextRating}>
                <FaChevronRight />
              </Button>
            ) : (
              <Button variant="link" className="groundNext" title="Zavřít" onClick={() => handleNav('ratings')}>
                <FaTimes />
              </Button>
            )}
          </div>
        )}
      </>)}
    </>
  );

}

export default TrainerDetail;
