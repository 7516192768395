import React, { useEffect, useReducer, useContext, useCallback } from 'react';
import useApi from 'utils/useApi';
import autocompleteConfig from 'config/components/autocomplete';
import googleMapsApi from 'utils/googleMapsApi';
import AppContext from './AppContext';
import MapContext from 'context/Map/MapContext';

const AppContextProvider = (props) => {

  const api = useApi();
  const { positionDispatch } = useContext(MapContext);

  const [location, locationDispatch] = useReducer((location, action) => {
    switch (action.type) {

      case 'address':
        return { address: action.address, source: 'address' };

      case 'device':
        return { address: '', source: 'device' };

      case 'map':
        return { address: '', source: 'map' };

      case 'reset':
        return null;

      default:
        throw new Error('Unexpected action ' + action.type);
    }
  }, null);

  // update map position on location change
  useEffect(() => {
    if (!location) {
      positionDispatch({ type: 'reset' });
      return;
    }

    if (location.source === 'device') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(result => {
          positionDispatch({ 
            type: 'set', 
            lat: result.coords.latitude, 
            lng: result.coords.longitude, 
            zoom: 14
          });
        });
      } else {
        locationDispatch({ type: 'reset' });
      }
    } else if (location.source === 'address') {
      googleMapsApi.then(google => {
        const autocomplete = new google.maps.places.AutocompleteService();

        autocomplete.getPlacePredictions({ input: location.address, ...autocompleteConfig }, (results, status) => {
          if (status !== "OK" || results.length === 0) {
            return;
          }

          const address = results[0].description;

          if (address !== location.address) {
            locationDispatch({ type: 'address', address });
            return;
          }

          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address }, (results, status) => {
            if (status !== 'OK') {
              return;
            }

            positionDispatch({ 
              type: 'set', 
              lat: results[0].geometry.location.lat(), 
              lng: results[0].geometry.location.lng(), 
              zoom: 14
            });
          });
        });
      });
    }

  }, [location, positionDispatch, locationDispatch]);



  const [ground, groundDispatch] = useReducer((ground, action) => {
    switch (action.type) {

      case 'set':
        return action.ground.loaded
          ? action.ground
          : { ...action.ground, loaded: false };

      case 'reset':
        return null;

      default:
        throw new Error('Unexpected action ' + action.type);
    }
  }, null);

  useEffect(() => {
    if (ground && ground.loaded === false) {
      api('GET', 'grounds/' + ground.id)
        .then(data => groundDispatch({ type: "set", ground: { 
          ...data, 
          loaded: true, 
          distance: ground.distance 
        }}));
    }
  }, [ground, api]);


  const [trainer, trainerDispatch] = useReducer((trainer, action) => {
    switch (action.type) {

      case 'set':
        return action.trainer.loaded
          ? action.trainer
          : { ...action.trainer, loaded: false };

      case 'reset':
        return null;

      default:
        throw new Error('Unexpected action ' + action.type);
    }
  }, null);

  useEffect(() => {
    if (trainer && trainer.loaded === false) {
      api('GET', 'trainers/' + trainer.id)
        .then(data => {
          trainerDispatch({ type: "set", trainer: { ...data, loaded: true } })
        });
    }
  }, [trainer, api]);



  const handleGroundDispatch = useCallback(dispatch => {
    if (dispatch.type === 'reset') {
      trainerDispatch({ type: 'reset' });
      location && location.source === 'map' && locationDispatch({ type: 'reset' });
    } else if (dispatch.type === 'set') {
      !location && locationDispatch({ type: 'map' });
    }

    groundDispatch(dispatch);
  }, [location]);

  const handleLocationDispatch = useCallback(dispatch => {
    if (dispatch.type === 'reset') {
      trainerDispatch({ type: 'reset' });
      groundDispatch({ type: 'reset' });
    }

    locationDispatch(dispatch);
  }, []);

  return (
    <AppContext.Provider value={{
      ground,
      groundDispatch: handleGroundDispatch,
      location,
      locationDispatch: handleLocationDispatch,
      trainer,
      trainerDispatch,
    }}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;