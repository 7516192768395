import React, { useState } from 'react';
import { pdaInqVersion } from 'config/config';
import getFirstName from 'utils/getFirstName';
import useApi from 'utils/useApi';
import Spinner from 'components/Spinner';
import { Button, Form } from 'react-bootstrap';

const TrainerInquiry = ({ trainer, ground, onSuccess }) => {

  const api = useApi();

  const [data, setData] = useState({
    email: "",
    name: "",
    text: "",
  });

  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const handleChangeEmail = email => {
    setData(prev => ({...prev, email}));
  };

  const handleChangeName = name => {
    setData(prev => ({...prev, name}));
  };

  const handleChangeText = text => {
    setData(prev => ({...prev, text}));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setState({ loading: true, error: null, });

    api('POST', 'trainers/' + trainer.id + '/inquiries', {
      ...data, 
      ground: ground.id,
      personalDataAgreeVersion: pdaInqVersion,
    }).then(() => onSuccess())
      .catch(error => setState({ loading: false, error }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <p className="mb-4">
        Níže můžeš nezávazně poptat vybraného trenéra, který se&nbsp;bude snažit co&nbsp;nejdříve odpovědět.
        Tak do&nbsp;toho, <strong>{getFirstName(trainer.name)}</strong> už tě vyhlíží 
        na&nbsp;hřišti <strong>{ground.title}</strong> :)
      </p>

      <Form.Control 
        value={data.name}
        className="my-3" 
        type="text" 
        placeholder="Tvé jméno" 
        onChange={e => handleChangeName(e.target.value)}
        required
      />
      <Form.Control 
        value={data.email}
        className="my-3" 
        type="email" 
        placeholder="Tvůj kontaktní e-mail" 
        onChange={e => handleChangeEmail(e.target.value)}
        required
      />
      <Form.Control
        value={data.text}
        as="textarea" rows="4" 
        placeholder="Napiš trenérovi něco o sobě, případně s čím potřebuješ pomoci nebo poradit." 
        onChange={e => handleChangeText(e.target.value)}
        required
      />

      <Form.Group controlId="personalDataAgree" className="text-center pt-4">
        <Form.Check type="switch"
          id="personalDataAgree"
          className="d-inline-block"
          label=""
          required
        />
        <Form.Label className="align-middle">
          Souhlasím se&nbsp;
            <a
            target="blank"
            href={"/documents/personal_data_agree_inquiry_v" + pdaInqVersion + ".pdf"}>
            zpracováním osobních údajů
            </a>
        </Form.Label>
      </Form.Group>

      {state.error && <p className="mb-3 text-danger">{state.error}</p>}

      <div className="text-center my-3">
        {state.loading 
          ? <Spinner />
          : <Button type="submit" size="lg" id="inquiry-submit">Odeslat trenérovi</Button>
        }
      </div>
    </Form>
  );

}

export default TrainerInquiry;
