import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from 'utils/ErrorBoundary';
import { CookiesProvider } from 'react-cookie';
import ContextProvider from 'context/ContextProvider';
import App from 'App';
import 'styles/index.scss';

ReactDOM.render((
  <ErrorBoundary>
    <CookiesProvider>
      <ContextProvider>
        <App />
      </ContextProvider>
    </CookiesProvider>
  </ErrorBoundary>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();