import React, { useState } from 'react';
import { apiUrl } from 'config/config';
import getImageThumb from 'utils/getImageThumb';
import Gallery from 'components/Gallery';
import { FaUser } from 'react-icons/fa';
import 'styles/components/avatar.scss';

const Avatar = ({ path, size, clickable }) => {

  const [showFull, setShowFull] = useState(false);

  size = size ? size : 'lg';

  const fullImage = apiUrl.replace('api/', '') + path;
  const thumbImage = getImageThumb(fullImage);

  return (
    <>
      <div className={"avatar avatar-" + size + (clickable && path ? ' clickable' : '')} 
        onClick={() => clickable && path && setShowFull(true)}
      >
        {!path ? (
          <FaUser 
            size={size === 'lg' ? 45 : 25} 
            className={size === 'lg' ? 'mt-3' : 'mt2'} 
          />
        ) : (
          <img src={thumbImage} alt="Avatar" />
        )}
      </div>
      {showFull && <Gallery photos={[fullImage]} handleClose={() => setShowFull(false)} />}
    </>
  );
};

export default Avatar;
