import React from 'react';
import Rating from 'components/Rating';
import { FaUser } from 'react-icons/fa';

const GroundListItem = ({ ground }) => {

  return (
    <>
      <div className="groundInfo">
        <strong className="d-block">{ground.title}</strong>
        <small>{ground.address}</small>
      </div>
      <div className="text-right ml-auto pl-2">
        {ground.rating && (<Rating className="rating" value={ground.rating} compact="true" />)}
        {ground.trainers > 0 && (<strong className="ml-3 d-inline-block" title="Počet trenérů"><FaUser className="icon text-primary" />&nbsp;{ground.trainers}</strong>)}
        <br />
        <small><strong>{ground.distance}&nbsp;km</strong></small>
      </div>
    </>
  );

}

export default GroundListItem;
