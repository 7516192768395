export const apiUrl = 'https://api.street-trainer.com/api/';
// export const apiUrl = 'http://localhost:8000/api/';

export const googleMapsApiKey = 'AIzaSyDTxkUrKE5jJPcFB_mf5Z9-rw7Q1gUWomY';

export const slackLogger = {
    webhook: "https://hooks.slack.com/services/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",
    username: "app",
    emoji: ":react:",
};

export const pdaRegVersion = '1';
export const pdaInqVersion = '1';
export const pdaGroundSugVersion = '1';

export const contact = {
    name: "Martin Příhoda",
    email: "info@street-trainer.com", 
    phone: "797996543",
    web: "https://www.martinprihoda.cz",
    facebook: "https://www.facebook.com/streettrainercz/",
};
