import React from 'react';
import UserContextProvider from 'context/User/UserContextProvider';
import MapContextProvider from 'context/Map/MapContextProvider';
import AppContextProvider from 'context/App/AppContextProvider';
import NotificationContext from 'context/Notification/NotificationContextProvider';
import GroundListContextProvider from 'context/GroundList/GroundListContextProvider';
import { BrowserRouter as Router } from "react-router-dom"

const ContextProvider = ({ children }) => (
  <NotificationContext>
    <UserContextProvider>
      <MapContextProvider>
        <GroundListContextProvider>
          <AppContextProvider>
            <Router>
              {children}
            </Router>
          </AppContextProvider>
        </GroundListContextProvider>
      </MapContextProvider>
    </UserContextProvider>
  </NotificationContext>
);

export default ContextProvider;
