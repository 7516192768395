import React from 'react';
import { slackLogger } from 'config/config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    fetch(slackLogger.webhook, {
      method: 'POST',
      body: JSON.stringify({
        "icon_emoji": slackLogger.emoji,
        "username": slackLogger.username,
        "attachments": [{
          "color": "danger",
          "title": 'ERROR',
          "fields": [{
            "title": "Message",
            "value": error.toString(),
            "short": false
          }, {
            "title": "Url",
            "value": document.location.href,
            "short": false
          }, {
            "title": "Component Stack",
            "value": errorInfo.componentStack,
            "short": false
          }, {
            "title": "Call Stack",
            "value": error.stack,
            "short": false
          }],
        }]
      }),
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="text-center w-100 p-5">
          <h3>Ups, nastala chyba, s&nbsp;kteoru jsme úplně nepočítali.</h3>
          <p className="pt-3">Víme o tom a snažíme se to co nejdříve vyřešit. Vrať se, prosím, později. Za vzniklé pozíže se omlouváme.</p>
          <a className="btn btn-outline-primary py-2" href="/">Na hlavní stranu</a>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;