import React, { useState, Suspense, useEffect } from 'react';
import NetworkDetector from 'components/NetworkDetector';
import useCompactLayout from "utils/useCompactLayout";
import Spinner from 'components/Spinner';
import Header from 'components/Header';
import Notification from 'components/Notification';
import Navigation from 'components/Navigation';
import { Route, Switch, useLocation } from "react-router-dom"
import Home from 'pages/Home/Home';

const Map = React.lazy(() => import('components/Map/Map'));
const Trainer = React.lazy(() => import('pages/Trainer/Trainer'));
const TrainerDetail = React.lazy(() => import('pages/Trainer/TrainerDetail'));
const About = React.lazy(() => import('pages/About/About'));
const Contact = React.lazy(() => import('pages/Contact/Contact'));
const Actions = React.lazy(() => import('pages/Actions/Actions'));
const NotFound = React.lazy(() => import('pages/NotFound'));

const App = () => {
  const compact = useCompactLayout();
  const [showNav, setShowNav] = useState(false);
  const [showMap, setshowMap] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setShowNav(show => !show);
  }

  useEffect(() => {
    if (compact && location.pathname === '/map') {
      setshowMap(true);
    }
  }, [compact, location]);

  return (
    <div id="app">
      <div id="main">
        <NetworkDetector />

        <Header isNavOpen={showNav} toggleNav={(toggleNav)} />

        {showNav ? <Navigation hideNav={() => setShowNav(false)} /> : (
          <>
            <Notification />
            <Suspense fallback={<Spinner usage="screen" />}>

              <Switch>
                <Route path={['/', '/map']} exact component={Home} />
                <Route path='/ground' component={Home} />
                <Route path='/trainer' component={Trainer} />
                <Route path='/about' component={About} />
                <Route path='/contact' component={Contact} />
                <Route path='/actions' component={Actions} />
                <Route component={NotFound} />
              </Switch>

            </Suspense>
          </>
        )}
      </div>

      {compact ? (
        <Switch>
          <Route path='/trainer/:detail' render={(params) => (
            <div id="detail" className='fullscreen'>
              <Suspense fallback={<Spinner usage='screen' />}>
                <TrainerDetail {...params}/>
              </Suspense>
            </div>
          )}/>
          {showMap && 
            <Route render={({ location }) => (
              <div id="detail" className={(location.pathname !== '/map' ? 'd-none' : 'fullscreen position-fixed')}>
                <Suspense fallback={<Spinner usage='screen' />}>
                  <Map />
                </Suspense>
              </div>
            )} />
          }
        </Switch>
      ): (
        <div id="detail">
          <Suspense fallback={null}>

            <Switch>
              <Route path='/trainer/:detail' component={TrainerDetail} />
              <Route component={Map} />
            </Switch>

          </Suspense>
        </div>
      )}
    </div>
  );
};

export default App;
