import React, {useState, useEffect} from 'react';

const NetworkDetector = (props) => {
  
  const [connected, setConnected] = useState(true);

  const setOnline = () => {
    setConnected(true)
  }

  const setOffline = () => {
    setConnected(false)
  }

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, []);
  
  if (connected) {
    return null;
  }

  return (
    <div className="position-sticky sticky-top py-3 text-center bg-body">
      <div className="p-2 rounded bg-danger">
        Zařízení není připojeno k internetu.
      </div>
    </div>
  );
}

export default NetworkDetector;