import React, { useState, useContext, useCallback, useEffect } from 'react';
import useCompactLayout from 'utils/useCompactLayout';
import LocationForm from './LocationForm';
import AppContext from 'context/App/AppContext';
import { Button } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useHistory, Link } from "react-router-dom";
import { scroller } from 'react-scroll';

const Location = () => {

  const history = useHistory();
  const compact = useCompactLayout();
  const { location, locationDispatch } = useContext(AppContext);

  const [focusInput, setFocusInput] = useState(false);

  const handleUpdate = useCallback((address, fromDevice = false) => {
    locationDispatch(fromDevice ? { type: 'device' } : { type: 'address', address });
  }, [locationDispatch]);

  const handleReset = () => {
    history.push('/');
    locationDispatch({ type: 'reset' });
    setFocusInput(true);
  };

  useEffect(() => {
    if (location) {
      scroller.scrollTo("step2", { smooth: true, offset: -20 });
    }
  }, [location]);

  return (
    <div id="step1" className="step">

      <h4>
        <strong className="stepNumber">01 </strong>
        {location ? (
          <>
            Lokalita
            <Button
              variant="secondary"
              size="sm"
              className="ml-2 align-text-bottom"
              onClick={handleReset}
            >
              Změnit
            </Button>
          </>
        ) : "Zadej lokalitu"}
        {compact && !location &&
          <Link to="/map">
            <Button
              size="sm"
              className="ml-2 align-text-bottom float-right"
            >
              Mapa
            </Button>
          </Link>
        }
      </h4>

      <div className="stepContent">
        {location ?
          <strong>
            <FaMapMarkerAlt className="mr-2" />
            {location.source === 'address'
              ? location.address
              : "Aktuální poloha"
            }
          </strong>
         : 
          <LocationForm handleUpdate={handleUpdate} focus={focusInput} />
        }
      </div>

    </div>
  );
}

export default Location;
