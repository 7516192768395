import React, { useContext, useState, useEffect } from 'react';
import TrainerList from './TrainerList';
import AppContext from 'context/App/AppContext';
import TrainerDetail from './TrainerDetail';
import TrainerInquiry from './TrainerInquiry';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { FaDumbbell } from 'react-icons/fa';
import { scroller } from 'react-scroll';

const Trainer = () => {

  const history = useHistory();
  const { ground, trainer } = useContext(AppContext);

  const [showInquiry, setShowInquiry] = useState(false);
  

  const handleShowInquiry = () => {
    setShowInquiry(true);
  };

  const handleSuccessInquiry = () => {
    setShowInquiry(false);
    history.push('/actions/thank-you');
  };

  useEffect(() => {
    if (showInquiry) {
      scroller.scrollTo("trainerInquiry", { smooth: true, offset: -20 });
    } else {
      setShowInquiry(false);
      if (trainer) {
        scroller.scrollTo("step3", { smooth: true, offset: -20 });
      }
    }
  }, [trainer, showInquiry]);

  return (
    <div id="step3" className={"step " + ((!ground || !ground.loaded) && "disabled")}>

      <h4>
        <strong className="stepNumber">03 </strong>
        {trainer ? (
          <>
            Trenér
            <Link to={'/ground/' + ground.id}><Button
              variant="secondary"
              size="sm"
              className="ml-2 align-text-bottom"
            >
              Změnit
            </Button></Link>
          </>
        ) : 'Vyber trenéra'}
      </h4>

      <div className="stepContent">
        {trainer ? (
           <>
              <TrainerDetail trainer={trainer} />
              {trainer.loaded && !showInquiry && <>
                {trainer.active ? (
                  <div className="text-center my-4">
                    <Button variant="primary" onClick={handleShowInquiry}>
                      Nezávazně poptat
                    </Button>
                  </div>
                ) : (
                  <div className="text-center my-4">
                    <Link to={"/ground/" + ground.id}>
                      <Button variant="outline-primary">
                        Vybrat jiného trenéra
                      </Button>
                    </Link>
                  </div>
                )}
              </>}
            </>
          ) : <TrainerList ground={ground} />
        }
      </div>

      {trainer && trainer.loaded && showInquiry && <>
        <h4 id="trainerInquiry" className="mt-4">
          <strong className="stepNumber"><FaDumbbell /> </strong>
          Poptat trenéra
        </h4>

        <div className="stepContent">
          <TrainerInquiry trainer={trainer} ground={ground} onSuccess={handleSuccessInquiry} />
        </div>
      </>}

    </div>
  );

}

export default Trainer;
