import React, { useEffect, useContext, useState } from 'react';
import Spinner from 'components/Spinner';
import Location from './Location/Location';
import Ground from './Ground/Ground';
import Trainer from './Trainer/Trainer';
import AppContext from 'context/App/AppContext';
import GroundListContext from 'context/GroundList/GroundListContext';
import { useRouteMatch, useHistory } from "react-router-dom";
import 'styles/pages/home.scss';

const Home = () => {
  
  const { grounds } = useContext(GroundListContext);
  const { ground, groundDispatch, trainer, trainerDispatch } = useContext(AppContext);
  const history = useHistory();

  const match = useRouteMatch("/ground/:ground/(trainer)?/:trainer?");
  const isMap = useRouteMatch("/map") ? true : false;
  const groundMatch = match && match.params.ground ? parseInt(match.params.ground) : null;
  const trainerMatch = match && match.params.trainer ? parseInt(match.params.trainer) : null;

  const [loading, setLoading] = useState(match ? true : false);

  // Set ground from path
  useEffect(() => {
    if (isMap) {
      return;
    }
    
    if (!groundMatch) {
      ground && groundDispatch({type: 'reset'});
      return;
    }
    
    if (grounds.length === 0) {
      return;
    }

    if (!ground || groundMatch !== ground.id) {
      const ground = grounds.find(g => g.id === groundMatch);

      if (ground) {
        groundDispatch({type: 'set', ground});
      } else history.push('/404');
    }
  }, [groundMatch, ground, grounds, groundDispatch, history, isMap]);

  // Set trainer from path
  useEffect(() => {
    if (isMap) {
      return;
    }

    if (!trainerMatch) {
      trainer && trainerDispatch({type: 'reset'});
      return;
    }

    if (!ground || !ground.loaded) {
      return;
    }

    if (!trainer || trainerMatch !== trainer.id) {
      const trainer = ground.trainers.find(t => t.id === trainerMatch);

      if (trainer) {
        trainerDispatch({type: 'set', trainer});
      } else history.push('/404');
    }

  }, [trainerMatch, ground, trainer, trainerDispatch, history, isMap]);

   // handle loading
   useEffect(() => {
    if (!trainerMatch && groundMatch && ground && ground.loaded) {
      setLoading(false);
    }

    if (trainerMatch && trainer && trainer.loaded) {
      setLoading(false);
    }
  }, [trainerMatch, trainer, groundMatch, ground]);

  return (
    <main>

      <h3 className="subtitle">
        Najdi nejbližší <strong>posilovací hřiště</strong> v&nbsp;okolí
        a&nbsp;vyber si <strong>trenéra</strong>
      </h3>

      {loading
        ? <Spinner />
        : <>
          <Location />
          <Ground />
          <Trainer />
        </>}

    </main>
  );

};

export default Home;
