import React, { useReducer } from 'react';
import MapContext from './MapContext';

const MapContextProvider = (props) => {

  const [activeMarker, activeMarkerDispatch] = useReducer((activeMarker, action) => {
    switch (action.type) {
      case 'set':
        return action.id

      case 'reset':
        return null;

      default:
        throw new Error("Unexpected action " + action.type);
    }
  }, null);

  const [position, positionDispatch] = useReducer((position, action) => {
    switch (action.type) {
      case 'set':
        return { lat: action.lat, lng: action.lng, zoom: action.zoom }

      case 'reset':
        return null;

      case 'zoomOut':
        if (!position) {
          return null;
        }
        
        return { ...position, zoom: position.zoom - 1 }

      default:
        throw new Error("Unexpected action " + action.type);
    }
  }, null);

  return (
    <MapContext.Provider value={{
      activeMarker,
      activeMarkerDispatch,
      position,
      positionDispatch,
    }}>
      {props.children}
    </MapContext.Provider>
  );
}

export default MapContextProvider;