import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import googleAutocomplete from 'utils/googleAutocomplete';

const LocationForm = ({ handleUpdate, focus }) => {

  const [value, setValue] = useState("");
  const ref = useRef(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
      setValue(event.target.value);
      return false;
    }
  }

  const handleSearch = (event) => {
    event.preventDefault();
    handleUpdate(ref.current.value);
  }

  const enableDeviceLocation = (event) => {
    event.preventDefault();
    handleUpdate("", true);
  }

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (ref.current) {
      googleAutocomplete(ref.current, (result) => {
        handleUpdate(result);
      });
    }
  }, [ref, handleUpdate]);

  return (
    <Form onSubmit={handleSearch} className="mb-3">
      <p>
        Zadej adresu a nebo <a href="#main" onClick={enableDeviceLocation}>použij aktuální polohu</a>,
        abychom mohli najít hřiště ve tvém okolí.
      </p>

      <InputGroup className="locationForm" size="lg">
        <Form.Control
          ref={ref}
          type="text"
          placeholder="Tvoje adresa..."
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          required
        />
        <InputGroup.Append>
          <Button variant="primary" type="submit">Hledej</Button>
        </InputGroup.Append>
      </InputGroup>

    </Form>
  );
}

export default LocationForm;
