import React from 'react';
import Avatar from 'components/Avatar';
import Rating from 'components/Rating';
import { Badge } from 'react-bootstrap';

const TrainerListItem = ({ trainer }) => {

  return (
    <>
      <div className={!trainer.active ? 'not-active' : ''}>
        <Avatar path={trainer.avatar} size="sm" />
      </div>
      <div className="ml-3">
        <strong>{trainer.name}</strong><br />
        {trainer.active ? (
          <span className="rating">
            {trainer.rating ? (
              <span title="Hodnocení trenéra od klientů">
                <Rating value={trainer.rating} />
                <small className="ml-2">
                  <strong>{trainer.rating} <Badge variant="light">{trainer.ratingCount}x</Badge></strong>
                </small>
              </span>
            ) : (
              <small title="Své první hodnocení trenér ještě neobdržel.">
                Zatím nehodnocen
              </small>
            )}
          </span>
        ) : (
          <span>
            <small>Dočasně nepřijímá další klienty</small>
          </span>
        )}
      </div>
    </>
  );

}

export default TrainerListItem;
