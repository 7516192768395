import React, { useContext, useEffect } from 'react';
import NotificationContext from "context/Notification/NotificationContext";
import { Alert } from 'react-bootstrap';
import 'styles/components/notification.scss';

const Notification = () => {

  const { notifications, notificationDispatch } = useContext(NotificationContext);

  const handleDelete = (id) => {
    notificationDispatch({ type: 'remove', id });
  };

  useEffect(() => {
    return () => notificationDispatch({ type: 'clear' });
  }, [notificationDispatch]);

  return (
    <div className="alert-box">
      {notifications.map((n, i) => (
        <Alert 
          key={n.id}
          variant={n.type} 
          className={!n.permanent ? 'alert-with-slideout' : ''}
          onClose={() => handleDelete(n.id)} 
          onAnimationEnd={() => handleDelete(n.id)}
          dismissible
        >
          {n.message}
        </Alert>
      ))}
    </div>
  );
  
};

export default Notification;
