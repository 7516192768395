import React, { useState, useRef, useEffect } from 'react';
import Spinner from 'components/Spinner';
import { Button } from 'react-bootstrap';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'styles/components/gallery.scss';

const Gallery = ({ photos, defaultPhoto, handleClose }) => {

  const ref = useRef(null);
  const [current, setCurrent] = useState(defaultPhoto ? defaultPhoto : 0);
  const [loading, setLoading] = useState(true);

  const handleConsiderClose = (e) => {
    if (e.target.classList.contains('closable')) {
      handleClose(e);
    }
  };

  const handlePrev = () => {
    setLoading(true);
    setCurrent(prev => prev - 1);
  };

  const handleNext = () => {
    setLoading(true);
    setCurrent(prev => prev + 1);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 37 && photos[current - 1]) {
      // Arrow left pressed
      handlePrev();
    } else if (e.keyCode === 39 && photos[current + 1]) {
      // Arrow right pressed
      handleNext();
    } else if (e.keyCode === 27) {
      // Esc pressed
      handleClose(e);
    }
    ref.current.focus();
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return (
    <div 
      className='gallery transparentBackground closable'
      onClick={handleConsiderClose}
      onKeyDown={handleKeyDown}
      ref={ref}
      tabIndex="0"
    >
      <div className="galleryHeader">
        {photos[current - 1] && (
          <Button onClick={handlePrev} className="mx-2" title="Předchozí">
            <FaChevronLeft />
          </Button>
        )}
        {photos[current + 1] && (
          <Button onClick={handleNext} className="mx-2" title="Další">
            <FaChevronRight />
          </Button>
        )}
        <Button onClick={handleClose} className="mx-5" title="Zavřít">
          <FaTimes />
        </Button>
      </div>
      <div className={'imageContainer closable'} onClick={handleConsiderClose}>
        {loading && <Spinner />}
        <img className={loading ? 'd-none' : ''} src={photos[current]} alt="Fotografie" onLoad={() => setLoading(false)} />
      </div>
    </div>
  );

};

export default Gallery;