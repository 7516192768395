import React, { useContext, useState, useEffect } from 'react';
import Spinner from 'components/Spinner';
import MapContext from 'context/Map/MapContext';
import GroundListItem from './GroundListItem';
import GroundListContext from 'context/GroundList/GroundListContext';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GroundList = ({ location }) => {

  const paginationStep = 6;
  
  const { position, positionDispatch, activeMarkerDispatch } = useContext(MapContext);
  const { grounds, sortByDistance } = useContext(GroundListContext);

  const [state, setState] = useState({
    visibleGrounds: [],
    currentPosition: null,
    showEmptyMessage: false,
  });

  const handleLoadMore = () => {
    const nextGrounds = grounds.slice(state.visibleGrounds.length, state.visibleGrounds.length + paginationStep);
    setState(prev => ({
      visibleGrounds: [...prev.visibleGrounds, ...nextGrounds],
      currentPosition: prev.currentPosition,
      showEmptyMessage: false,
    }));
    positionDispatch({ type: 'zoomOut' });
  };

  const handleMouseEnter = (groundId) => {
    activeMarkerDispatch({ type: 'set', id: groundId });
  };

  const handleMouseLeave = () => {
    activeMarkerDispatch({ type: 'reset' });
  };

  useEffect(() => {
    if (!position) {
      setState({
        visibleGrounds: [],
        currentPosition: null,
        showEmptyMessage: false,
      });
      return;
    }

    const positionWithoutZoom = { lat: position.lat, lng: position.lng }
    if (!state.currentPosition
      || (positionWithoutZoom.lat !== state.currentPosition.lat
        && positionWithoutZoom.lng !== state.currentPosition.lng)
    ) {

      setState({
        visibleGrounds: [],
        currentPosition: positionWithoutZoom,
        showEmptyMessage: false,
      });

      sortByDistance(positionWithoutZoom);
    }
  }, [position, state.currentPosition, sortByDistance]);

  // refresh on grounds change
  useEffect(() => {
    const toDisplay = grounds.slice(0, paginationStep);
    const nearLocation = toDisplay.filter(g => !g.distance || g.distance < 5);
    setState(prev => ({
      visibleGrounds: nearLocation.length === 0 ? toDisplay.slice(0, 1) : nearLocation,
      currentPosition: prev.currentPosition,
      showEmptyMessage: nearLocation.length === 0,
    }));
  }, [grounds]);

  return (
    <>
      <p className="mb-3">
        {location && state.showEmptyMessage
          ? "V blízkosti tvé lokality toho moc není :("
          : "Zvol si hřiště v okolí dle hodnocení nebo dostupnosti."
        }
      </p>
      {location && <>
        {state.currentPosition === null || state.visibleGrounds.length === 0 
          ? <Spinner />
          : <>
          {state.visibleGrounds.map(g => 
            <Link to={'/ground/' + g.id} 
              key={g.id}
              className="stepItem"
              onMouseEnter={() => handleMouseEnter(g.id)}
              onMouseLeave={handleMouseLeave}
            >
              <GroundListItem ground={g} />
            </Link>
          )}
          <div className="mt-3 text-center">
            <Link to="/actions/suggest-ground">
              <Button variant="outline-primary"
                className="mx-2 my-2">
                Chybí tu hřiště
              </Button>
            </Link>
            {state.visibleGrounds.length < grounds.length && (
              <Button
                onClick={handleLoadMore}
                className="mx-2 my-2"
              >
                Načíst další
              </Button>
            )}
          </div>
          </>
        }
      </>}
    </>
  );

}

export default GroundList;
