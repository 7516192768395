import React from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

const Header = ({ isNavOpen, toggleNav }) => {
  return (
    <header>

      <Button 
        variant="default" 
        className="btn-menu" 
        onClick={toggleNav} 
        title="Hlavní nabídka"
      >
        {isNavOpen ? <FaTimes /> : <FaBars />}
      </Button>

      <a href={window.location.origin}>
        <h1 className="title">
          street <strong>trainer</strong>
        </h1>
      </a>

    </header>
  );
};

export default Header;
