import React, { useContext, useState, useEffect } from 'react';
import distanceCalculator from 'utils/distanceCalculator';
import TrainerListItem from './TrainerListItem';
import GroundListContext from 'context/GroundList/GroundListContext';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TrainerList = ({ ground }) => {

  const { grounds, sortByDistance } = useContext(GroundListContext);
  const [ nearestGround, setNearestGround ] = useState(null);

  useEffect(() => {
    if (ground && ground.loaded && ground.trainers.length === 0) {
      async function lookForClosestGround() {
  
        ground.distance = 0;
        const sortedGrounds = grounds.map(g => {
          const distance = distanceCalculator(
            ground.lat,
            ground.lng,
            g.lat,
            g.lng
          );
    
          return { ...g, distance }
        }).sort((a, b) => a.distance - b.distance);

        const index = sortedGrounds.findIndex(e => e.id === ground.id);

        if (index === -1) {
          return;
        }
        
        let leftIndex = index - 1;
        let rightIndex = index + 1;
        let leftGround = sortedGrounds[leftIndex];
        let rightGround = sortedGrounds[rightIndex];
        let leftEnd = leftGround ? false : true;
        let rightEnd = rightGround ? false : true;
        let attempt = 0;
        const maxAttempt = 600;

        while (attempt < maxAttempt && (!rightEnd || !leftEnd)) {
          let look = 'left';
          if (leftEnd) {
            look = 'right';
          } else if (rightEnd) {
            look = 'left';
          } else {
            const leftGroundDistance = ground.distance - leftGround.distance;
            const rightGroundDistance = rightGround.distance - ground.distance;
            look = leftGroundDistance < rightGroundDistance ? 'left' : 'right';
          }

          if (look === 'left') {
            if (leftGround.trainers > 0) {
              setNearestGround(leftGround);
              return;
            } else if (sortedGrounds[leftIndex - 1]) {
              leftIndex--;
            } else {
              leftEnd = true;
            }
          } else {
            if (rightGround.trainers > 0) {
              setNearestGround(rightGround);
              return;
            } else if (sortedGrounds[rightIndex + 1]) {
              rightIndex++;
            } else {
              rightEnd = true;
            }
          }

          attempt++;
          leftGround = sortedGrounds[leftIndex];
          rightGround = sortedGrounds[rightIndex];
        }

        setNearestGround(null);
      };

      lookForClosestGround();
    }
  }, [ground, grounds, sortByDistance]);

  return (
    <>
      {!ground || !ground.loaded ? (
        <p className="mb-3">
          Ze seznamu dostupných trenérů pro dané hřiště si na&nbsp;základě profilu a&nbsp;hodnocení 
          vybereš toho správného na&nbsp;svojí cestu.
        </p>
      ) : (
        <>
          {ground.trainers.length === 0 ? (
            <>
            <p className="mb-3">
              Na tomto hřišti bohužel žádný trenér nepůsobí.  
              {nearestGround && (
                <>
                  &nbsp;Nejbližší hřiště s&nbsp;trenérem:&nbsp;
                  <Link to={'/ground/' + nearestGround.id}>
                    {nearestGround.title}
                  </Link>.
                </>
              )}
            </p>
            <div className="text-center mt-4">
              <Link to='/'>
                <Button className="m-2">Vybrat jiné hřiště</Button>
              </Link>
              <Link to='/trainer'>
                <Button variant="outline-primary" className="m-2">
                  Stát se trenérem
                </Button>
              </Link>
            </div>
            </>
          ) : (
            <p className="mb-3">
              Níže jsou dostupní trenéři pro toto hřiště. Kliknutím na trenéra zobrazíš jeho profil.
            </p>
          )}

          {ground.trainers.map(t => 
            <Link key={t.id} className="stepItem"
              to={'/ground/' + ground.id + '/trainer/' + t.id}
            >
              <TrainerListItem trainer={t} />
            </Link>
          )}

        </>
      )}
    </>
  );

}

export default TrainerList;
