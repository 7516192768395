import googleMapsApi from 'utils/googleMapsApi';
import config from 'config/components/autocomplete';

export default (ref, callback) => {
  
  googleMapsApi.then(google => {
    const ac = new google.maps.places.Autocomplete(ref, config);
    ac.setFields(['geometry']);

    ac.addListener('place_changed', () => callback(ref.value));
  });

}
