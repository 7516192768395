import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';

const Spinner = (props) => {

  if (props.usage === "screen") {
    return (
      <div className="p-5 m-5 text-center">
        <BootstrapSpinner
          animation="border"
          variant="primary"
          {...props}
        />
      </div>
    );
  }

  if (props.usage === "inline") {
    return (
      <span className="px-4">
        <BootstrapSpinner
          animation="border"
          variant="primary"
          size="sm"
          at="span"
          {...props}
        />
      </span>
    );
  }

  return (
    <div className="p-2 text-center">
      <BootstrapSpinner
        animation="border"
        variant="primary"
        {...props}
      />
    </div>
  );

};

export default Spinner;