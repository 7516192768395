import React, { useState, useEffect } from 'react';
import googlePlaceDetail from 'utils/googlePlaceDetail';
import useApi from 'utils/useApi';
import Rating from 'components/Rating';
import Gallery from 'components/Gallery';
import Spinner from 'components/Spinner';
import { Badge, Button } from 'react-bootstrap';
import { FaChevronRight, FaGoogle, FaTimes } from 'react-icons/fa';

const initState= {
  detailLoaded: false,
  rating: null,
  ratingTotal: null,
  reviews: [],
  reviewsPage: 0,
  showReviews: false,
  photos: [],
  photosPage: 0,
  showPhotos: false,
};

const GroundDetail = ({ ground }) => {

  const [state, setState] = useState(initState);
  const [activePhoto, setActivePhoto] = useState(null);
  const api = useApi();

  const handleTogglePhotos = () => {
    setState(prev => ({
      ...prev, 
      showPhotos: !prev.showPhotos, 
      showReviews: false, 
      photosPage: 0
    }));
  };

  const handleToggleReviews = () => {
    setState(prev => ({
      ...prev, 
      showReviews: !prev.showReviews, 
      showPhotos: false, 
      reviewsPage: 0
    }));
  };

  const handleNextReview = () => {
    setState(prev => ({
      ...prev, 
      reviewsPage: prev.reviewsPage + 1
    }));
  };

  const handleNextPhotos = () => {
    setState(prev => ({
      ...prev, 
      photosPage: prev.photosPage + 1
    }));
  };

  const handleShowPhoto = (key) => {
    setActivePhoto(key);
  }

  useEffect(() => {
    if (ground.loaded) {
      setState(initState)
      if (ground.google_place_id) {
        googlePlaceDetail(ground.google_place_id, (data) => {
          setState(prev => ({
            ...prev,
            detailLoaded: true,
            rating: data.rating,
            ratingTotal: data.ratingTotal,
            reviews: data.reviews,
            reviewsPage: 0,
            photos: data.photos,
            photosPage: 0,
          }));

          if (ground.google_rating && data.rating !== ground.google_rating) {
            api('POST', 'grounds/' + ground.id + '/rating', {rating: data.rating});
          }
        });
      }
    }
  }, [ground, api]);

  if (ground.loaded === false) {
    return <Spinner />;
  }

  return (
    <>
      <div className="stepItem active">
        <div className="groundInfo">
          <strong className="d-block">{ground.title}</strong>
          <small>{ground.address}</small>
        </div>
        {state.rating && (
          <div className="align-self-center text-right ml-auto pl-5 rating" title="Hodnocení hřiště na Google">
            <Rating value={state.rating} /><br />
            <small>
              <strong>
                {state.rating} {state.ratingTotal && <Badge variant="light" className="ml-1">{state.ratingTotal}x</Badge>}
              </strong>
            </small>
          </div>
        )}
      </div>

      {state.detailLoaded && (
        <>

          <nav className="stepNav">
            {state.photos.length > 0 && (
              <Button variant="link" className={state.showPhotos ? ' active' : ''} onClick={handleTogglePhotos}>
                Fotogalerie
              </Button>
            )}
            {state.reviews.length > 0 && (
              <Button variant="link" className={state.showReviews ? ' active' : ''} onClick={handleToggleReviews}>
                Recenze
              </Button>
            )}
            <a href={"https://www.google.com/maps/search/?api=1&query=" + ground.lat + "," + ground.lng} 
              target="blank" 
              className="ml-auto" 
              title="Hřiště na Google Maps"
            >
              <FaGoogle size="13" />
            </a>
          </nav>

          {state.showPhotos && (
            <>
            <div className="groundGallery">
              {state.photos.slice(state.photosPage * 4, (state.photosPage * 4) + 4).map((photo, key) => (
                <div key={key} 
                  className="image" 
                  style={{backgroundImage: "url(" + photo.thumbUrl + ")"}} 
                  onClick={() => handleShowPhoto((state.photosPage * 4) + key)}
                  alt="Náhled fotografie" 
                />
              ))}
              {state.photos.length > ((state.photosPage + 1) * 4) ? (
                <Button variant="link" className="groundNext ml-auto" title="Další" onClick={handleNextPhotos}>
                  <FaChevronRight />
                </Button>
              ) : (
                <Button variant="link" className="groundNext" title="Zavřít" onClick={handleTogglePhotos}>
                  <FaTimes />
                </Button>
              )}
            </div>
            {activePhoto !== null && 
              <Gallery 
                photos={state.photos.map(p => p.url)} 
                defaultPhoto={activePhoto} 
                handleClose={() => setActivePhoto(null)} 
              />
            }
            </>
          )}

          {state.showReviews && (
            <>
            <div className="groundReviews">
              <div className="review">
                <blockquote>
                  „{state.reviews[state.reviewsPage].text}“ 
                  <span className="text-darker"> - {state.reviews[state.reviewsPage].author}</span>
                </blockquote>
              </div>
              {state.reviews.length - 1 > state.reviewsPage ? (
                <Button variant="link" className="groundNext ml-auto" title="Další" onClick={handleNextReview}>
                  <FaChevronRight />
                </Button>
              ) : (
                <Button variant="link" className="groundNext" title="Zavřít" onClick={handleToggleReviews}>
                  <FaTimes />
                </Button>
              )}
            </div>
            </>
          )}
        </>
      )}
    </>
  );

}

export default GroundDetail;
